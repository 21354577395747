import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import releaseConfig from '!!raw-loader!./release.config.js';
import action from '!!raw-loader!./action.yml';
import { Code } from "gatsby-theme-docz/src/components/Code";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "semantic-release"
    }}>{`Semantic Release`}</h1>
    <p>{`A Github Action for release a package under registry.wild.plus.`}</p>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`On every push on the `}<strong parentName="p">{`main`}</strong>{` branch:`}</p>
    <ul>
      <li parentName="ul">{`If needed, a new package version will be published under registry.wild.plus.`}</li>
      <li parentName="ul">{`Based on the commit history, a changelog will be generated.`}</li>
      <li parentName="ul">{`A Github release will be created.`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <ul>
      <li parentName="ul">{`Install semantic-release, launching `}<inlineCode parentName="li">{`yarn add @semantic-release/changelog @semantic-release/git semantic-release -D`}</inlineCode></li>
      <li parentName="ul">{`Create a new `}<inlineCode parentName="li">{`release`}</inlineCode>{` script in your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{`:`}</li>
    </ul>
    <Code className="json" mdxType="Code">
      {`{
  ...
  "scripts": {
    ...
    "release": "semantic-release"
  }
}`}
    </Code>
    <ul>
      <li parentName="ul">{`Add a new `}<inlineCode parentName="li">{`publishConfig`}</inlineCode>{` field in your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{`:`}</li>
    </ul>
    <Code className="json" mdxType="Code">
      {`{
  ...
  "publishConfig": {
    "registry": "https://registry.wild.plus/"
  },
}`}
    </Code>
    <ul>
      <li parentName="ul">{`Then add this to a `}<inlineCode parentName="li">{`release.config.js`}</inlineCode>{` file:`}</li>
    </ul>
    <Code className="js" mdxType="Code">
  {releaseConfig}
    </Code>
    <ul>
      <li parentName="ul">{`Then add this to a `}<inlineCode parentName="li">{`.github/workflows/action.yml`}</inlineCode>{` file:`}</li>
    </ul>
    <Code className="yaml" mdxType="Code">
  {action}
    </Code>
    <ul>
      <li parentName="ul">{`Create a secret clicking on `}<strong parentName="li">{`Settings > Secrets > New Repository Secrets`}</strong>{`:`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPM_TOKEN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search `}<inlineCode parentName="td">{`registry.wild.plus`}</inlineCode>{` on 1Password`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "publish-under-registrynpmjsorg"
    }}>{`Publish under registry.npmjs.org`}</h2>
    <p>{`In some cases, you might need to publish directly under registry.npmjs.org.`}<br parentName="p"></br>{`
`}{`Be sure to follow these steps:`}</p>
    <ul>
      <li parentName="ul">{`Drop the `}<inlineCode parentName="li">{`publishConfig`}</inlineCode>{` field in `}<inlineCode parentName="li">{`package.json`}</inlineCode></li>
      <li parentName="ul">{`Search `}<inlineCode parentName="li">{`NPM wild user`}</inlineCode>{` on 1Password and replace the NPM_TOKEN value.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      